import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PageWrapper from '../../../components/src/Pagewrapper.web';
import { SearchOutlined } from '@ant-design/icons';
import { Input as InputData, Space, Spin, Table } from 'antd';
import { ClientList } from "./AssessmentTestController";
// Customizable Area End

import AssessmentTestController, {
  Props,
} from "./AssessmentTestController";
import { AssessmentItemInterface, AttemptedAssessmentInterface } from "./IAssessment";

export default class AssessmentTest extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
	columns:any = [
		{
			title: (<Span>Client Name</Span>),
			width: 280,
			ellipsis: true,
			render: (event: ClientList) => (
				<TableData className='name-text'>
					{event.full_name}
				</TableData>
			),
		},
		{
			title: (<Span>Total Assessments</Span>),
			width: 280,
			ellipsis: true,
			render: (event: ClientList) => (
				<TableData className='name-text'>
					{event.total_assessments}
				</TableData>
			),
		},
		{
			title: (<Span>Action</Span>),
			dataIndex: '',
			width: 280,
			key: (event: ClientList) => event.id,
			render: (event:ClientList) => <a className='view-text' onClick={()=>this.getClientID(event.id)}>View</a>,
		},
	];

  CustomEmpty = (loader:boolean) => (
		<div style={{ textAlign: 'center',}}>
			<div style={{width:"100%",
			display:"flex",
			justifyContent:"center",
			backgroundColor:"red",
			height:"30%"}}>
		  <Spin spinning={loader} />
			</div>
		  <div
			style={{
			  height: '50px',
			  backgroundSize: 'contain',
			  backgroundRepeat: 'no-repeat',
			  backgroundPosition: 'center',
			  padding:"0px 20px",
			  marginBottom:"10px"
			}}
		  />
		 <span >No Data</span>
		</div>
	  );
    handlePagination = (page:any) =>{
      localStorage.setItem("ClientPage",page);
      this.setState({pagination:page})
    } 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <PageWrapper title="Assessments">
        <HeadingContainer>
          <HeadingBox>
            <P className='case-title'>ALL CLIENTS</P>
            <Space>
              <SearchBar
                name="search"
                onChange={this.handleSearchData}
                placeholder="Search by Client name"
                prefix={<SearchOutlined style={{ color: '#94A3B8', marginLeft: '6px', fontSize: '18px', }} />}
                style={{ width: '360', color: '#64748B', fontSize: '16px', borderRadius: '28px', height: '44px', border: '1px solid #CBD5E1', padding: '4px' }}
              />
            </Space>
          </HeadingBox>
          <TableDesign
            data-testid="AssessmentTable"
            columns={this.columns}
            bordered
            dataSource={this.getDataSource()}
            scroll={{
              y: "calc(100vh - 410px)"
            }}
            pagination={{
              current: this.state.pagination ?this.state.pagination:1 ,
              onChange:this.handlePagination
            }}
            locale={{
							emptyText: this.CustomEmpty(this.state.clientListLoader)
						  }}
          />
        </HeadingContainer>
      </PageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const HeadingContainer = styled("div")({
  marginTop: "40px",
  background: "white",
  borderRadius: "8px 8px 32px 8px",
  marginBottom: "10px",
});

const HeadingBox = styled('div')(({ theme }) => ({
  padding: "28px 14px 28px 24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down('xs')]: {
    display:"block",
  },

}));
const SearchBar = styled(InputData)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width:"100% !important",

  },
}));
const P = styled("p")({
  color: "#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  marginTop: "14px",
})
const Span = styled("span")({
  color:"#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  marginTop: "4px"
})
const TableData = styled("span")(({theme})=>({
  color: "#000",
  fontSize: "14px",
  fontWeight: 400,
  [theme.breakpoints.down('xs')]: {
    fontSize: "13px",
  },
}))

const TableDesign = styled(Table)({
  borderBottomLeftRadius: "8px" ,
  borderBottomRightRadius: "32px",
  overflow: "hidden",
  "& .ant-table-thead .ant-table-cell": {
    borderBottom: "1px solid #E2E8F0 !important",
    background: "#F1F5F9 !important",
  }
})

// Customizable Area End
