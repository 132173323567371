// Customizable Area Start
import { SuccessMessage } from "../../../components/src/Toast";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import moment from 'moment';

export interface Props {
	navigation: any;
	id: string;
	match: any;
}
interface CaseData {
    attributes: {
        created_at: string;
        unique_reference_number: string;
        full_name: string;
    };
}

interface CreateCaseValues {
    first_name: string;
    last_name: string;
    unique_reference_number: string;
    mobile_number: number;
    email: string;
}

interface CreateUserValues {
    email: string;
    case_id: string;
    manager_type: string;
    full_name: string;
}

export interface ModalValue{
	Assessment:string,
	Frequency:string
  }

interface AddAppointmentValues {
	name: string;
	title: string;
	location: string;
	purpose: string;
	information: string;
	date:any;
	starttime:any;
	endtime:any;
	type:string
}
export interface Assessment2{
	id: number,
	type: string,
	attributes: {
		client_id: number,
		account_id: number,
		assessment_name: string,
		assigned_date: string,
		period: string,
		status: string,
		score: string,
		assigned_by: string,
	}
  }


interface S {
	casesData: any,
    loadingCaseData: boolean,
    filteredCasesData: CaseData[],
    loadingCreateCase: boolean,
    redirectToDashboard: boolean,
	createCaseErrorMsg: string,
	moodData: any,
	upcomingAppointmentList: any,
	goalData: any,
	taskData: any
	taskR1Data: any,
	loadingCreateUser: boolean,
	showAddUserModal: boolean,
	appointmentsR1Data: any,
	currentRoleType: any,
	createUserErrorMsg: string,
	caseStatus: string,
	caseAssigned: any,
	fullName: string,
	email: string,
	showViewUserModal: boolean,
	deallocateUserModal: boolean,
	deallocateLoader: boolean,
	assistant_case_manager: {full_name:string,id:number}[],
    solicitor: string,
    guest: string,
	case_manager: string,
	roleId: any,
	caseManagerId: any
	assistantCaseManagerId: any,
	solicitorId: any,
	guestId: any,
	showClientModal: boolean,
	showAddTaskPopup: boolean,
	loadingAddTask: boolean,
	addTaskErrorMsg: string,
	errorMsg: string,
	showAddModel: boolean,
	loadingData: boolean,
	dateValue:Date | null,
	dateError:string | null,
	unique_reference_number: string,
	editStatusPopup: boolean,
	ClientStatus: string | null
	loadingEditStatus: boolean,
	activeKey: any,
	userList: any,
	assessmentData: any,
	showAddAssessment: boolean,
	createAssessmentErrorMsg: any,
	addAssessmentLoader: boolean
}

interface SS {
	id: any;
}



export default class CaseController extends BlockComponent<Props, S, SS> {
	//cases
	apiResetGetCasesFormCallId: string = "";
    apiResetCreateCasePostCallId: string = "";
	apiResetGetMoodsCallId: string = "";
	apiGetUpcomingAppointmentsFormCallId: string = "";
	getUserGoalApiCallID: string = "";
	apiResetGetTaskCallId: string = "";
	apiResetGetR1TaskCallId: string = "";
	apiResetCreateUserPostCallId: string = "";
	apiResetGetR1AppointmentsCallId: string = "";
	apiResetGetCaseByIdCallId: string = "";
	apiResetGetViewDetailsCallId: string = "";
	apiResetDeallocateUserCallId: string = "";
	apiResetGetDetailsByIdCallId: string = "";
	apiAddTaskPostCallId: string = "";
	apiCreateAppointmentPostCallId: string = "";
	apiEditStatusPostCallId: string = "";
	apiRoleWiseUserListPostCallId: string = "";
	apiResetGetCaseDetailsByIdCallId: string = "";
	apiResetGetAssessmentCallId: string = "";
	apiResetAddAssessmentCallId: string = "";
	

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
		];
		this.state = {
			casesData: [],
            loadingCaseData: false,
            filteredCasesData: [],
            loadingCreateCase: false,
            redirectToDashboard: false,
			createCaseErrorMsg: '',
			moodData: [],
			upcomingAppointmentList: [],
			goalData: {
				short_term: [],
				long_term: {},
				medium_term: []
			},
			taskData: [],
			taskR1Data: [],
			ClientStatus:localStorage.getItem("ClientStatus"),
			loadingCreateUser: false,
			showAddUserModal: false,
			appointmentsR1Data: [],
			currentRoleType: '',
			createUserErrorMsg: '',
			caseStatus: '',
			caseAssigned: '',
			fullName: '',
			email: '',
			showViewUserModal: false,
			deallocateUserModal: false,
			deallocateLoader: false,
			assistant_case_manager: [],
			solicitor: '',
			guest: '',
			case_manager: '',
			roleId: 0,
			caseManagerId: 0,
			assistantCaseManagerId: 0,
			solicitorId: 0,
			guestId: 0,
			showClientModal: false,
			showAddTaskPopup: false,
			loadingAddTask: false,
			addTaskErrorMsg: '',
			errorMsg: '',
			showAddModel: false,
			loadingData: false,
			dateValue:null,
			dateError:null,
			unique_reference_number: '',
			editStatusPopup: false,
			loadingEditStatus: false,
			activeKey: [],
			userList: [],
			assessmentData: [],
			showAddAssessment: false,
			createAssessmentErrorMsg: '',
			addAssessmentLoader: false
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async receive(from: string, message: Message) {
		this.receiveGetCases(message);
        this.receiveCreateCase(message);
		this.receiveGetMoods(message);
		this.receiveUpcomingAppointment(message);
		this.receiveGetAllGoals(message);
		this.receiveGetTasks(message);
		this.receiveGetR1Tasks(message);
		this.receiveCreateUser(message);
		this.receiveGetR1Appointments(message);
		this.receiveGetCaseById(message);
		this.receiveGetViewDetails(message);
		this.receiveDeallocateUser(message);
		this.receiveGetDetailsById(message);
		this.receiveAddTask(message);
		this.receiveAddAppointment(message);
		this.receiveEditStatus(message);
		this.receiveRoleWiseUserList(message);
		this.receiveGetCaseDetailsById(message);
		this.receiveGetAssessment(message);
		this.receiveAddAssessment(message);
	}

	async receiveGetCases(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetCasesFormCallId &&
            this.apiResetGetCasesFormCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			this.setState({casesData: responseJson && responseJson.data, filteredCasesData:responseJson && responseJson.data, loadingCaseData: false});
            
          }
	}

    async receiveCreateCase(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetCreateCasePostCallId &&
			this.apiResetCreateCasePostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
               
			 if(responseJson && responseJson.data){
				this.setState({redirectToDashboard: true, loadingCreateCase: false});
				SuccessMessage("Case created successfully")
			  }else if(responseJson.message){
				this.setState({loadingCreateCase: false, createCaseErrorMsg: responseJson.message});
				return;
			  }else if(responseJson.error){
				this.setState({loadingCreateCase: false});
				return;
			  }else{
                this.setState({loadingCreateCase: false, createCaseErrorMsg: responseJson.errors[0]})
				return;
			  }
		}
	}

	async receiveGetMoods(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetMoodsCallId &&
            this.apiResetGetMoodsCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson?.client_moods){
                this.setState({moodData: responseJson?.client_moods});
            }
            
          }
	}
	async receiveUpcomingAppointment(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetUpcomingAppointmentsFormCallId &&
			this.apiGetUpcomingAppointmentsFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson?.data){
				this.setState({upcomingAppointmentList: responseJson?.data});
			}

		}
	}

	async receiveGetAllGoals(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getUserGoalApiCallID &&
            this.getUserGoalApiCallID ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if (responseJson) {
				const goalData = {
					incompleted_goals: responseJson.incompleted_goals,
					completed_goals: responseJson.completed_goals
				};
				this.setState({ goalData });
			}
            
          }
	}

	async receiveGetTasks(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetTaskCallId &&
            this.apiResetGetTaskCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson?.data){
                this.setState({taskData: responseJson?.data});
				
            }
            
          }
	}
	async receiveGetAssessment(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetAssessmentCallId &&
            this.apiResetGetAssessmentCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson.assessments){
				this.setState({assessmentData:responseJson.assessments.data})
			}
            
          }
	}
	async receiveGetR1Tasks(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetR1TaskCallId &&
            this.apiResetGetR1TaskCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson?.tasks){
                this.setState({taskR1Data: responseJson?.tasks});
				
            }
            
          }
	}

	async receiveGetR1Appointments(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetR1AppointmentsCallId &&
            this.apiResetGetR1AppointmentsCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson?.appointments){
                this.setState({appointmentsR1Data: responseJson?.appointments});
				
            }
            
          }
	}
	async receiveCreateUser(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetCreateUserPostCallId &&
			this.apiResetCreateUserPostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
               
			 if(responseJson && responseJson?.data){
				this.setState({loadingCreateUser: false, showAddUserModal: false});
				SuccessMessage("User added successfully")
				this.getViewDetails();
			 }else if (responseJson && responseJson.error) {
				if (responseJson.error) {
					this.setState({loadingCreateUser: false, createUserErrorMsg: responseJson.error});
				}
				}else{
					this.setState({loadingCreateUser: false});
				}
		}
	}

	async receiveGetCaseById(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetCaseByIdCallId &&
            this.apiResetGetCaseByIdCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson?.data){
                this.setState({
					caseStatus: responseJson?.data?.attributes?.status,
					caseAssigned: responseJson?.data?.attributes?.created_at,
					fullName: responseJson?.data?.attributes?.full_name,
					email: responseJson?.data?.attributes?.email,
					unique_reference_number: responseJson?.data?.attributes?.unique_reference_number
				});
				
            }
            
          }
	}

	async receiveGetCaseDetailsById(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetCaseDetailsByIdCallId &&
            this.apiResetGetCaseDetailsByIdCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			this.goToAppointmentDetailsScreen(responseJson.data);
			if(responseJson && responseJson?.data){
                this.setState({
					caseStatus: responseJson?.data?.attributes?.status,
					caseAssigned: responseJson?.data?.attributes?.created_at,
					fullName: responseJson?.data?.attributes?.full_name,
					email: responseJson?.data?.attributes?.email,
					unique_reference_number: responseJson?.data?.attributes?.unique_reference_number
				});
				
            }
            
          }
	}
	async receiveGetViewDetails(message: any) {
		if (this.isResponseValid(message)) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	
			if (responseJson && responseJson.data) {
				this.updateStateWithManagerData(responseJson.data);
			}
		}
	}
	
	isResponseValid(message: any) {
		return (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetGetViewDetailsCallId &&
			this.apiResetGetViewDetailsCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		);
	}
	
	updateStateWithManagerData(data: any) {
		const managerTypes = [
			'case_manager',
			'assistant_case_manager',
			'solicitor',
			'guest',
		];
		const newState: any = {};
	
		managerTypes.forEach((type) => {
			newState[type] = data.filter((item: any) => item.attributes.manager_type === type)
            .map((manager: any) => {
                const handler = manager.attributes.case_handlers;
                return handler ? {
                    id: handler.id,
                    full_name: handler.full_name,
                } : null; 
            })
            .filter((manager: any) => manager !== null);
				
				
		});
	
		this.setState(newState);
	}

	async receiveDeallocateUser(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetDeallocateUserCallId &&
            this.apiResetDeallocateUserCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			
			if(responseJson && responseJson?.message){
				this.setState({deallocateLoader: false, deallocateUserModal: false})
				SuccessMessage(responseJson.message);
				window.location.reload()
				this.getViewDetails();
            }else{
				this.setState({deallocateLoader: false})
			}
            
          }
	}

	async receiveGetDetailsById(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetDetailsByIdCallId &&
            this.apiResetGetDetailsByIdCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson?.data){
                this.setState({
					fullName: responseJson?.data?.attributes?.full_name,
					email: responseJson?.data?.attributes?.email
				});
            }
          }
	}
	
	async receiveAddTask(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiAddTaskPostCallId &&
			this.apiAddTaskPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.task){
				this.getTasks()
				this.setState({ showAddTaskPopup: false, loadingAddTask: false });
				SuccessMessage("Task Added successfully");
				
			}else if(responseJson && responseJson.message){
				this.setState({ loadingAddTask: false, addTaskErrorMsg: responseJson.message});
			}else{
				this.setState({loadingAddTask: false})
			}
		}
	}

	async receiveAddAppointment(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiCreateAppointmentPostCallId &&
			this.apiCreateAppointmentPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.data){
				this.setState({ loadingData: false, showAddModel: false,errorMsg:"",dateValue:null});
				SuccessMessage("Appointment Added successfully");
				this.setState({ loadingData: false, showAddModel: false,errorMsg:"" ,dateValue:null});
				this.getR1Appointments();
			}else if(responseJson.error){
				this.setState({ loadingData: false,errorMsg:responseJson.error,dateValue:null});
			}else{
				this.setState({ loadingData: false,errorMsg:responseJson.errors,dateValue:null});
			}
		
			
		}
	}

	async receiveEditStatus(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiEditStatusPostCallId &&
			this.apiEditStatusPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.message){
				this.getCaseById();
				this.setState({ editStatusPopup: false, loadingEditStatus: false });
				SuccessMessage(responseJson.message);
			}else{
				this.setState({loadingEditStatus: false})
			}
		
		}
	}

	async receiveRoleWiseUserList(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiRoleWiseUserListPostCallId &&
            this.apiRoleWiseUserListPostCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson?.data){
                this.setState({userList: responseJson?.data});
				
            }
            
          }
	}

	  async receiveAddAssessment(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetAddAssessmentCallId &&
			this.apiResetAddAssessmentCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.assessment){
				this.getAssessments()
				this.setState({ showAddAssessment: false, addAssessmentLoader: false });
				SuccessMessage("Assessment added successfully");
				
			}
			}else{
				this.setState({addAssessmentLoader: false})
			}
		}
	
    getCasesData = () => {
        const token = localStorage.getItem("token");
        this.setState({loadingCaseData: true})
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetCasesFormCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  configJSON.getCasesApiEndPoint
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };


      createCase(values: CreateCaseValues): boolean {
		this.setState({ loadingCreateCase: true });
        const token = localStorage.getItem("token");
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
            "token": token
		};

		const httpBody = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "unique_reference_number": values.unique_reference_number,
            "mobile_number": values.mobile_number,
            "email": values.email
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetCreateCasePostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.createCaseApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	createUser(values: CreateUserValues): boolean {
      
		this.setState({ loadingCreateUser: true });
        const token = localStorage.getItem("token");
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
            "token": token
		};

		const httpBody = {
			"email": values.email,
			"full_name": values.full_name ? values.full_name.split('(')[0].trim() : '',
			"manager_type": this.state.currentRoleType,
			"case_id": this.getUrlParameter("case_id")
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetCreateUserPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.createUserApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

      handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value.toLowerCase();
        const filteredData = this.state.casesData.filter((item: any) =>
          item.attributes.unique_reference_number.toLowerCase().includes(searchText) ||
          item.attributes.full_name.toLowerCase().includes(searchText)
        );
      
        this.setState({ filteredCasesData: filteredData });
      };

	onCreateCase = (values: any) => {
		this.createCase(values);
	};

	onCreateUser = (values: any) => {
		this.createUser(values);
	};

	getMoods = () => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetMoodsCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.getMoodsApiEndPoint}${this.getUrlParameter("id")}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  getUpcomingAppointments = () => {
		const token = localStorage.getItem("token");
		const header = {
			"token": token
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetUpcomingAppointmentsFormCallId = requestMessage.messageId;
			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.getClientUpcomingAppointment}?case_id=${this.getUrlParameter("case_id")}`
			);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	getAlTermsGoals = () => {
		const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.validationApiContentType,
		  token: token,
		};
	
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.getUserGoalApiCallID = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.getUserGoalsEndApi}${this.getUrlParameter("id")}`
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
	
		return true;
	  };

	  getTasks = () => {
        const token = localStorage.getItem("token");
		const clientId = localStorage.getItem("clientId");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetTaskCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.getTaskEndApi}${this.getUrlParameter("id")}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  getR1Tasks = () => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetR1TaskCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.tasksForControllerApiEndPoint}${this.getUrlParameter("id")}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };
	  getR1Appointments = () => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetR1AppointmentsCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.appointmentsForControllerApiEndPoint}${this.getUrlParameter("case_id")}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  getCaseById = () => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetCaseByIdCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.caseByIdApiEndPoint}${this.getUrlParameter('case_id')}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  getViewDetails = () => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetViewDetailsCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.getViewDetails}${this.getUrlParameter('case_id')}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  deallocateUser = () => {
		this.setState({deallocateLoader: true})
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetDeallocateUserCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.deallocateApiEnd}case_id=${this.getUrlParameter("case_id")}&manager_type=${this.state.currentRoleType}&deallocated=true&case_handler_id=${this.state.roleId}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  getDetailsById = () => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetDetailsByIdCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.getDetailsByIdApiEndPoint}${this.state.roleId}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  addTask(values: any): boolean {
		const token = localStorage.getItem("token");
		this.setState({loadingAddTask: true});
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};
		const httpBody = {
			"title": values.title,
			"from_date": values.from_date,
			"due_date": values.due_date,
			"priority": values.priority,
			"client_id": this.getUrlParameter("id")
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiAddTaskPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.addTaskApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

	addAppointment(values: AddAppointmentValues): boolean {
		const caseID = localStorage.getItem("caseID");
		const token = localStorage.getItem("token");
		this.setState({ loadingData: true });
		const startTime = values.starttime ? moment(values.starttime, "HH:mm") : null;
		const formattedStartTime = startTime ? startTime.format("HH:mm") : null;
		const endTime = values.endtime ? moment(values.endtime, "HH:mm") : null;
		const formattedEndTime = endTime ? endTime.format("HH:mm") : null;
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};
		const httpBody = {
			"case_id": caseID,
			"name": values.name,
			"availability_date":this.state.dateValue,
			"start_time": formattedStartTime,
			"end_time": formattedEndTime,
			"title": values.title,
			"type_of_appointment": values.type,
			"location": values.location,
			"purpose": values.purpose,
			"additional_information": values.information
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiCreateAppointmentPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.addAppointmentApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

	editStatus(values: any): boolean {
		const token = localStorage.getItem("token");
		this.setState({loadingEditStatus: true});
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};
		const httpBody = {
			"status": values.status
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiEditStatusPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.editStatusApiEndPoint}${this.getUrlParameter("case_id")}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.patchApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

	getRoleWiseUserList = (role: any) => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiRoleWiseUserListPostCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.roleWiseUserList}${role}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  getAssessments = () => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetAssessmentCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.assessmentsApiEndPoint}${this.getUrlParameter("id")}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  addAssessment = async (values:ModalValue) => {
		this.setState({addAssessmentLoader: true})
		let token = localStorage.getItem("token")
	
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};
		const body = {
		  "client_id": this.getUrlParameter("id"),
		  "assessment_name": values.Assessment,
		  "period": values.Frequency
	  }
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		 configJSON.addAssessmentApiEndPoint
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		this.apiResetAddAssessmentCallId = requestMessage.messageId;
		body &&
		  requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(body)
		  );
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.apiMethodTypeAddDetail
		);
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return requestMessage.messageId;
	  };

	  getUrlParameter(name: any) {
		let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		let results = regex.exec(window?.location?.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}

	showCreateUserModal = (roleType: any) => {
		if(this.state.ClientStatus !== "closed"){
			this.getRoleWiseUserList(roleType);
			this.setState({ currentRoleType: roleType, showAddUserModal: true})
		}
	}
	handleCancelUserModal = () => {
		this.setState({showAddUserModal: false, createUserErrorMsg: ''});
	}
	showViewUserModal = (roleType: any, id: any) => {
		this.setState({ currentRoleType: roleType, showViewUserModal: true, roleId: id }, () => {
			this.getDetailsById();
		});
		
	}
	handleCancelViewModal = () => {
		this.setState({showViewUserModal: false})
	}
	showDeallocateModal = (roleType: any, caseHandlerId: any) => {
		this.setState({ currentRoleType: roleType, deallocateUserModal: true, roleId: caseHandlerId})
	}
	handleDeallocateModal = () => {
		this.setState({deallocateUserModal: false})
	}
	showClientModal = () => {
		this.setState({  showClientModal: true}, () => {
			this.getCaseById();
		});
	}
	handleClientModal = () => {
		this.setState({showClientModal: false})
	}

	showAddTaskPopup = () => {
		if(this.state.ClientStatus !== "closed"){
			this.setState({showAddTaskPopup: true})
		}
	}

	closeAddTaskModal = () => {
		this.setState({showAddTaskPopup: false});
	}

	onFinishAddTask = (values: any) => {
		this.addTask(values);
	};

	hideModel = () => {
		this.setState({errorMsg:"", showAddModel: false ,});
	}
	showModel = () => {
		if(this.state.ClientStatus !== "closed"){
			this.setState({errorMsg:"", showAddModel: true })
		}
	}

	saveData = (values: any) => {
		this.addAppointment(values);
	};
	customNameValidator = (_: any, value: string) => {
		if (!value || !/^[a-zA-Z\s]*$/.test(value)) {
		  return Promise.reject('Please enter a valid name');
		}
		return Promise.resolve();
	};
	handledatepicker = (current:any) => {
		return moment().add(-1, 'days') >= current
	}

	handlePopupContainer = (trigger: any) => {
		return trigger.parentElement
	}

	checkDate =()=>{
		this.state.dateValue === null &&
			this.setState({dateError:"Please select date"})
		
	}

	showEditStatusModal = (id: string) => {
		this.setState({editStatusPopup: true, roleId: id})
	}
	cancelEditStatusModal = () => {
		this.setState({editStatusPopup: false})
	}
    getCaseDetailsById=()=>{
		const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetCaseDetailsByIdCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.caseByIdApiEndPoint}${this.getUrlParameter('case_id')}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);

	}
	goToAppointmentDetailsScreen = (rowData:any) => {
		const goToDetails = new Message(getName(MessageEnum.NavigationMessage));
		goToDetails.addData(getName(MessageEnum.NavigationTargetMessage), "AppointmentDashboardDetails");

		goToDetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		const raiseMessage: Message = new Message(
			getName(MessageEnum.NavigationPayLoadMessage)
		);
		raiseMessage.addData("rowData", rowData);
		goToDetails.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

		this.send(goToDetails);

	}

	getDataSource = () => {
		return this.state.assessmentData.slice(0, 5)
	  }

	showAddAssessmentModal = () => {
		if(this.state.ClientStatus !== "closed"){
			this.setState({  showAddAssessment: true})
		}
	}
	handleCancelAssessmentModal = (formRef:any) => {
		this.setState({showAddAssessment: false, createAssessmentErrorMsg: ''});
		formRef?.current?.resetFields();
	}
	saveAssessmentData=(formRef:any)=>{
		this.addAssessment(formRef.current?.getFieldsValue());
		formRef?.current?.resetFields();
	  }

	getPopupContainer = (trigger:any)=>{
		return trigger.parentElement
	  }
	  navigate = async()=>{
		const goToDetails = new Message(getName(MessageEnum.NavigationMessage));
		  goToDetails.addData(getName(MessageEnum.NavigationTargetMessage), "GoalManagementDashboardDetail");
	  
		  goToDetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		  const raiseMessage: Message = new Message(
			getName(MessageEnum.NavigationPayLoadMessage)
		  );
		  goToDetails.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
	  
		  this.send(goToDetails);
	  
		}
	  navigatetoGoal=()=>{
		this.navigate()
	  }
}
// Customizable Area End