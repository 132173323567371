// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from '@ant-design/icons';
import { cancel, chevron_left, chevron_right, deleteTask, editTask, noData } from './assets';
import Calendar from 'react-calendar'
import TaskController from "./TaskController.web";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { Modal, Button, Form, Input, Select, DatePicker, Row, Col } from 'antd';
import './Task.css';
import moment from "moment";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

class TaskDetailsScreen extends  TaskController{
    formRef: any;
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}
    async componentDidMount(){
        const currentDate = moment(new Date()).format('DD-MM-yyyy');
        this.dateWiseGetClients(currentDate, this.getUrlParameter("id"));
    }
      renderAddTaskModal() {
		return (
			<Modal
				className="appointment-modal"
				id="task-modal"
				title={<div class="header-container1" style={{ padding: "36px 24px 15px" }}>
					<h2 style={{ fontSize: '24px', color: '#000000', fontWeight: 700 }}>
                    {this.state.isEditing ? 'Update Task' : 'Add Task'}
                    </h2>
					<img class="image" onClick={() => {
						this.formRef.current.resetFields()
						this.closeCreateTaskModal()
					}} style={{ height: '22px', width: '22px', color: '#334155' }} src={cancel} />
				</div>}
				open={this.state.showAddTask}
				closable={false}
				footer={null}
                width={440}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
			>
				<Form
					data-testid="save-data"
                    key={`${this.state.isEditing}${this.state.taskData?.id}`}
					ref={this.formRef}
					layout="vertical"
					className="form"
					style={{ padding: "0 24px 20px 24px", position: "relative" }}
                    initialValues={ this.state.isEditing ? {...this.state.taskData, 
                        from_date: moment(this.state.taskData?.from_date),
                        due_date: moment(this.state.taskData?.due_date)
                    } : {}}
					onFinish={() => {
						this.onFinishCreateTask(this.formRef.current.getFieldsValue(), this.state.isEditing ? this.state.taskData?.id : null);
						this.formRef.current.resetFields() 
					}}
					
				>
					<Row gutter={12}>
						<Col xs={24}>
							<Form.Item
								label={
                                    <span className="bold-label">
                                      <span>Task Name</span> 
                                      <span className="sub-text"> (max 20 characters)</span>
                                    </span>
                                  }
								name="title"
								className="bold-label"
								validateFirst
								rules={[
									{
										required: true,
										message: "Please enter name",
									},
									{ min: 3, max: 20 }
								]}

							>
								<Input maxLength={20} className="font-style" placeholder='Enter name of the task' />
							</Form.Item>
						</Col>
                        <Col xs={24}>
							<Form.Item
								name="priority"
								label="Time Period"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please select time period"
									},
								]}

							>

								<Select
									className="font-style apt-design"
									id="select-type"
									placeholder="Enter time period"
									options={[
										{ value: 'daily', label: 'Daily' },
										{ value: 'weekly', label: 'Weekly' },
                                        { value: 'monthly', label: 'Monthly'}
									]}
									
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
                                <Form.Item
                                    label="From Date"
                                    name="from_date"
                                    className="bold-label"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select from date"
                                        },
                                    ]}

							    >

                                    <DatePicker
                                        placeholder='Date of Entry'
                                        id="date"
                                        className="font-style time-design"
                                        format="DD MMM YYYY"
                                        picker="date"
                                        inputReadOnly={true}
                                    />
							    </Form.Item>
						</Col>
                        <Col xs={24}>
                                <Form.Item
                                    label="To Date"
                                    name="due_date"
                                    className="bold-label"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select to date"
                                        },
                                    ]}

							    >

                                    <DatePicker
                                        placeholder='Date of Entry'
                                        id="date"
                                        className="font-style time-design"
                                        format="DD MMM YYYY"
                                        picker="date"
                                        inputReadOnly={true}
                                    />
							    </Form.Item>
						</Col>
						<Col xs={24}>
                        <div style={{ width: '100%', height: '1px', backgroundColor: '#E0E0E0', margin: '20px 0' }}></div>
                        <div style={{display: 'flex', justifyContent: 'end', marginTop: '20px'}}>
								<Button
									data-testid="hide-model"
									type="primary"
									onClick={() => {
										this.closeCreateTaskModal()
										this.formRef.current.resetFields()
									}}
									style={{
										background: "#ffff",
										borderColor: "#ffff",
										height: "56px",
										width: '120px',
										color: "#830065",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									Cancel
								</Button>
								<Button
									loading={this.state.loadingCreateTask}
									htmlType="submit"
									type="primary"
									style={{
										background: "#830065",
										borderColor: "#830065",
										borderRadius: "8px",
										height: "56px",
										width: '120px',
										color: "white",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									 {this.state.isEditing ? 'Update' : 'Add'}
								</Button>
							</div>
						</Col>
                        {this.state.createErrorMsg !== '' ?
								<p data-test-id="error-msg" style={{ color: '#DC2626', textAlign: 'left' }}>{
									this.state.createErrorMsg}
								</p> : null}
					</Row>
				</Form>

			</Modal>
		);
	}
    renderStatus = (status) => {
        if (status === "pending") return <div className='pending-div'>Pending</div>;
        if (status === "ongoing") return <div className='ongoing-div'>Ongoing</div>;
        if (status === "completed") return <div className='completed-div'>Completed</div>;
    };
    renderActionButtons = (e) => (
        <div style={{ display: 'flex', justifyItems: 'center', justifyContent: 'flex-start' }}>
            <p className="view" disable={true} data-testid="reassign"
                onClick={() => {
                    if (e.attributes.status !== "pending" && e.attributes.status !== "ongoing") {
                        this.showAddTaskModal(e, true);
                    }
                }}
                style={{
                    cursor: (e.attributes.status === "pending" || e.attributes.status === "ongoing") ? "not-allowed" : "pointer",
                    color: (e.attributes.status === "pending" || e.attributes.status === "ongoing") ? "#94A3B8" : "#830065"
                }}
            >Reassign</p>
            {e.attributes.status !== "completed" && (
                <>
                    <img data-testid="delete-data" src={deleteTask} onClick={() => this.deleteModalPopup(e.attributes.id, e.attributes.title)} style={{ height: '24px', cursor: 'pointer', marginLeft: "12px" }} />
                    <img data-testid="edit-data" src={editTask} onClick={() => this.showAddTaskModal(e, true)} style={{ height: '24px', cursor: 'pointer', marginLeft: "12px" }} />
                </>
            )}
        </div>
    );
    
    renderActionCell = (e, userType) => {
        if (userType !== "client") {
            return (
                <TableCell style={{ backgroundColor: "white", width: '15%', fontSize: 14, fontWeight: 700, color: '#830065' }}>
                    {this.renderActionButtons(e)}
                </TableCell>
            );
        }
        return null;
    };
    
    renderUpdateCell = (e, userType) => {
        if (userType !== "case_controller" && userType !== "case_manager" && userType !== "assistant_case_manager") {
            return (
                <TableCell style={{ backgroundColor: "white", width: '15%', fontSize: 14, fontWeight: 700, color: '#830065' }}>
                    <p data-testid="update-status" onClick={() => this.showUpdateStatusModal(e.attributes.id)} className="view">Update</p>
                </TableCell>
            );
        }
        return null;
    };
    renderModalsDelete() {
		return (
			<Modal
			  className="delete-modal"
			  id="delete-modal"
			  open={this.state.deletePopup}
			  closable={false} 
			  footer={null}
			>
			<>
			  <div style={{
				padding: '30px 40px 0px 40px', fontSize: '20px',
				marginTop: '20px',fontWeight: 700
			  }}>
				Are you sure you want to delete "{this.state.taskName}" from tasks?
			  </div>
              <div style={{ width: '100%', height: '1px', backgroundColor: '#E0E0E0', margin: '20px 0' }}></div>
			  <div style={{ display: "flex", justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{
					  height: "56px",
					  width: "120px",
					  background: "#ffff",
					  borderColor: "#ffff",
					  borderRadius: "8px",
					  color: "#830065",
					  fontSize: '16px',
					  fontWeight: 700
					}}
					onClick={()=>this.deleteHandleCancel()} >Cancel</Button>
				<Button
					data-testid="delete-app"
					style={{
					  height: "56px",
					  width: "130px",
					  background: "#830065",
					  borderColor: "#830065",
					  borderRadius: "8px",
					  color: "white",
					  fontSize: '16px',
					  fontWeight: 700
					}}
					
					onClick={()=>this.deleteTask(this.state.taskId)
                    } >Yes, Delete</Button>
				</div>
			</>
			</Modal>
		);
	}

    renderUpdateStatusModal() {
		return (
			<Modal
				className="appointment-modal"
				id="update-status-modal"
				title={<div class="header-container1" style={{ padding: "36px 24px 15px" }}>
					<h2 style={{ fontSize: '24px', color: '#000000', fontWeight: 700 }}>
                    Task Status
                    </h2>
					<img class="image" onClick={() => {
						this.formRef.current.resetFields()
						this.closeUpdateStatusModal()
					}} style={{ height: '22px', width: '22px', color: '#334155' }} src={cancel} />
				</div>}
				open={this.state.showUpdateStatus}
				closable={false}
				footer={null}
                width={440}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
			>
				<Form
                    data-testid="save-status-data"
					ref={this.formRef}
					layout="vertical"
					className="form"
					style={{ padding: "0 24px 20px 24px", position: "relative" }}
					onFinish={() => {
						this.onFinishUpdateStatus(this.formRef.current.getFieldsValue(), this.state.taskId );
					}}

				>
					<Row gutter={12}>
                        <Col xs={24}>
							<Form.Item
								name="status"
								label="Select Status"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please select time period"
									},
								]}

							>

								<Select
									className="font-style apt-design"
									id="select-type"
									placeholder="Select Task Status"
									options={[
										{ value: 'completed', label: 'Completed' },
										{ value: 'ongoing', label: 'Ongoing' }
									]}
									
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
                        <div style={{ width: '100%', height: '1px', backgroundColor: '#E0E0E0', margin: '20px 0' }}></div>
                        <div style={{display: 'flex', justifyContent: 'end', marginTop: '20px'}}>
								<Button
									data-testid="cancel-status-model"
									type="primary"
									onClick={() => {
										this.closeUpdateStatusModal()
										this.formRef.current.resetFields()
									}}
									style={{
										background: "#ffff",
										borderColor: "#ffff",
										height: "56px",
										width: '120px',
										color: "#830065",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									Cancel
								</Button>
								<Button
									loading={this.state.loadingUpdateStatus}
									htmlType="submit"
									type="primary"
									style={{
										background: "#830065",
										borderColor: "#830065",
										borderRadius: "8px",
										height: "56px",
										width: '120px',
										color: "white",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									Add
								</Button>
							</div>
						</Col>
					</Row>
				</Form>

			</Modal>
		);
	}
    renderClientInfo = (userType) => {
        return (
			<>
				<div className="change-client-div" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					{userType !== "client" && (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<p style={{ marginRight: "24px" }}>Client: <strong>{this.getUrlParameter("name")}</strong></p>
							<Link to="/all-task-list"><p className="change-client">Change</p></Link>
						</div>
					)}
					<div style={{ marginLeft: 'auto' }}>
						<Link to={`/view-all-task?id=${this.getUrlParameter("id")}`}><p className="view-all-task">View All</p></Link>
					</div>
				</div>
				{userType !== "client" && <div className='user-library-divider'></div>}
				
			</>
        );
    }
    
	renderCalendar = () => {
		return (
			<Row className='rowstyle' gutter={[16, 16]}>
				<Col xs={24} sm={24} md={12} lg={12} xl={9}>
					<div className='apt-calender' style={{
						margin: '0 auto',
						padding: '10px',
						boxSizing: 'border-box'
					}}>
						<Calendar
							className='calender-back'
							data-testid="calendar-data"
							prevLabel={<img className="image" src={chevron_left} />}
							nextLabel={<img className="image" src={chevron_right} />}
							next2Label={null}
							prev2Label={null}
							onChange={date => this.dateWiseGetClients(moment(date).format('DD-MM-yyyy'), this.getUrlParameter("id"))}
							defaultValue={new Date(this.getUrlParameter("date"))}
						/>
					</div>
				</Col>
			</Row>
		);
	}

    renderNoData = () => {
        return (
            <div style={{ background: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '8px 8px 32px 8px' }}>
                <img src={noData} alt="No Data" style={{ width: '88px', height: '87px' }} />
                <div style={{ fontSize: '16px', fontFamily: 400, color: '#475569', marginTop: '10px', marginBottom: '10px' }}>No task added yet</div>
            </div>
        );
    }
    renderAddTaskButton = (userType) => {
        return (
            userType !== "client" &&
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <PlusCircleOutlined className='add-icon3' />
                <p data-testid="add" onClick={() => this.showAddTaskModal(null, false)} className='add-file' style={{ margin: '0 auto' }}>Add Task</p>
            </div>
        );
    }
	render(): React.ReactNode {
        const userType = localStorage.getItem('userType');
		return (
			<PageWrapper title="My Tasks" hasBack >
                   {this.renderClientInfo(userType)}
                    {this.renderCalendar()}
                        <div className='search-content'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p className='case-title'>List of Tasks : {moment(this.state.selectedDate, 'DD-MM-YYYY').format('DD MMMM YYYY')}</p>
                                {this.renderAddTaskButton(userType)}
                            </div>
                        </div>
                        
                        <div>
                {this.state.dateWiseAllData && this.state.dateWiseAllData.length > 0 ? (
					<TableContainer component={Paper} style={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '0px 0px 32px 8px' }}>
						<Table aria-label="simple table" style={{minWidth: '800px' , borderRadius: '0 0 32px 8px', overflow: 'hidden' }} data-test-id="table">
							<TableHead style={{ backgroundColor: '#F1F5F9' }}>
								<TableRow>
									<TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '20%', whiteSpace: 'nowrap',textOverflow: 'ellipsis', maxWidth: '10ch' }}>Task Name</TableCell>
									<TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned by</TableCell>
									<TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned on</TableCell>
									<TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned Till</TableCell>
									<TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Status</TableCell>
									<TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Period</TableCell>
									<TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.dateWiseAllData.map((e, i) => (
									<TableRow key={i} className={i === this.state.dateWiseAllData.length - 1 ? 'last-row' : ''}>
										<TableCell style={{ backgroundColor: "white", width: '20%', fontSize: 14, fontWeight: 400, color: "#0F172A", whiteSpace: 'nowrap',textOverflow: 'ellipsis', maxWidth: '10ch' }}>{(e?.attributes?.title ?? '').charAt(0).toUpperCase() + (e?.attributes?.title ?? '').slice(1)}</TableCell>
										<TableCell style={{ backgroundColor: "white", width: '15%', fontSize: 14, fontWeight: 400, color: "#0F172A" }}>{(e?.attributes?.assigned_by ?? '').charAt(0).toUpperCase() + (e?.attributes?.assigned_by ?? '').slice(1)}</TableCell>
										<TableCell style={{ backgroundColor: "white", width: '15%', fontSize: 14, fontWeight: 400, color: "#0F172A" }}>{moment(e.attributes.from_date).format('D MMM YYYY')}</TableCell>
										<TableCell style={{ backgroundColor: "white", width: '15%', fontSize: 14, fontWeight: 400, color: "#0F172A" }}>{moment(e.attributes.due_date).format('D MMM YYYY')}</TableCell>
										<TableCell style={{ backgroundColor: "white", width: '10%' }}>
										{this.renderStatus(e.attributes.status)}
										</TableCell>
										<TableCell style={{ backgroundColor: "white", width: '10%', fontSize: 14, fontWeight: 400, color: "#0F172A" }}>{(e?.attributes?.priority ?? '').charAt(0).toUpperCase() + (e?.attributes?.priority ?? '').slice(1)}</TableCell>
										{this.renderActionCell(e, userType)}
											{this.renderUpdateCell(e, userType)}
										
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
                ) :this.renderNoData()}
            </div>
                           
                        
            {this.renderAddTaskModal()}
            {this.renderModalsDelete()}
            {this.renderUpdateStatusModal()}
			</PageWrapper>
		);
	}
}

export default TaskDetailsScreen;
// Customizable Area End this