// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from "react";
import rehab from "../assets/rehab.png";
import login_image from "../assets/login_image.png";
import { Button, Checkbox, Col, Form, Input, Row, Spin } from "antd";
import "./EmailAccountLogin.css";
import EmailAccountLoginController from "../../email-account-registration/src/EmailAccountRegistrationController.web";
import { Link, Redirect } from "react-router-dom";

class EmailAccountLoginBlock extends EmailAccountLoginController {
	constructor(props: any) {
		super(props);
		this.generateDeviceId();
	}
	async componentDidMount(){
        this.getDynamicTextAndImageForLogin()
	}

	render(): React.ReactNode {
		const userType = localStorage.getItem('userType');
		if (this.state.redirectToOtp) {
			return (userType === 'client') ? <Redirect to='/client-dashboard' /> : <Redirect to='/dashboard' />;
		}
		return (
			<>
				<Row gutter={12} style={{height:'100%'}}>
					<Col xs={24} sm={12}  style={{ paddingBottom: '120px',background: "rgba(251,241,249,255)",}}>
						<div className="centered-content-login">
							<img style={{ width: "435px", marginBottom: '44px',height:"91px",marginTop:"120px" }} src={rehab} />
							<img
								style={{ width: "523px",height:"350px", marginBottom: '70px'}}
								src={this.state.image_data}
								alt="rehab"
							/>
							<p className="headline-1" style={{margin:"auto",textAlign:"center",width:"423px",height:"44px",marginBottom:"16px",lineHeight:1 }}>{this.state.title}</p>

							<p className="feature-content" style={{width:"423px",margin:"auto",textAlign:"center",height:"52px"}}>
								{this.state.description}
							</p>
							
						</div>
					</Col>
					<Col xs={24} sm={12}>
						<div id="login" className="centered-content-right1">
							<p className="headline-2-login">Login</p>
							<Form
								layout="vertical"
								initialValues={{
									email: this.state.email,
									password: this.state.password,
									remember: this.state.remember,
								}}
								className="form"
								onFinish={this.onLogin}
								
							>
								<Row gutter={12}>
									<Col xs={24}>
										<Form.Item
											style={{height:"84px",width:"360px"}}
											label="Email"
											name="email"
											className="bold-label"
											rules={[{ type: 'email', required: true, message: 'Please Enter Your Email' }]}
										>
											<Input 
												className={this.state.loginErrorMessage ? "input-error" : ""}
												placeholder="Your email" 
												onChange={(e) => this.setState({ email: e.target.value,loginErrorMessage: null })}
												/>
										</Form.Item>
									</Col>
									<Col xs={24}>
										<Form.Item
										 rules={[{ required: true, message: 'Please Enter Your Password' }]}
											label={
												<div
													style={{
														display: "flex",
														justifyContent:
															"space-between",
														width: "100%",
														height: "24px",
													}}
												>
													<p className="bold-labels" >
														Password
													</p>
													<p style={{fontSize: '16px', fontWeight: '600'}}>
														<a
															style={{
																color: "#830065",
																fontSize: '13px', fontWeight: 400
															}}
															href="/forget-password"
														>
															Forgot Password?
														</a>
													</p>
												</div>
											}
											name="password"
											style={{height:"84px",width:"360px"}}
										>
											<Input.Password 
												className={`${this.state.loginErrorMessage ? "input-error" : ""} } specific-input2`}
												placeholder="Your password" 
												style={{ color: this.state.loginErrorMessage ? 'red' : 'inherit' }}
												onChange={(e) => {this.setState({password: e.target.value,loginErrorMessage: null});}}
																								/>
												
										</Form.Item>
										{this.state.loginErrorMessage ? (<span  style={{ color: '#DC2626', float: 'left' }} id="loginInErrorMsg">
{this.state.loginErrorMessage}
</span>
   
):( "")}
									</Col>
									
										
									<Form.Item
										name="remember"
										valuePropName="checked"
									>
										<div style={{marginLeft: '8px', display:"flex",justifyContent:"center",alignItems:"center"}}>
											<Checkbox  id="rememberMe" onChange={this.handleRememberMeChange}></Checkbox>
											<span style={{fontSize:"14px",fontWeight:400,marginLeft:"10px"}}>Remember me</span>
										</div>
									</Form.Item>
									
									<Col xs={24}>
											<div style={{margin: "10px 0px 20px 0px",height:"56px",width:"360px", borderRadius:"8px"}}>
											<Button
												id="login_button"
												htmlType="submit"
												type="primary"
												loading={this.state.loadingLogin}
												style={{ 
													background: "#830065",
													borderColor: "#830065",
													borderRadius: "5px",
													height: "100%",
													width: "100%",
													color: "white",
													fontSize:"16px",
													fontWeight: "700",
													
												}}
											>
												Login
											</Button>
											</div>
											
									</Col>
									<div style={{ display: "flex", marginLeft: '7px' }}>
										<p style={{fontSize: '16px', fontWeight: 400, color: '#0F172A'}}>Don't have an account?</p>&nbsp;
										<Link to="/signup">
											<a
												id="login_btn"
												style={{
													color: "#830065",
													fontSize: '16px', 
													fontWeight: 700
												}}
											>
												Create Account
											</a>
										</Link>
									</div>
								</Row>
							</Form>
						</div>
					</Col>
				</Row>
			</>
		);
	}
}

export default EmailAccountLoginBlock;
// Customizable Area End
