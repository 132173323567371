// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from "react";
import rehab from "../assets/rehab.png";
import forgot from "../assets/forgot.png";
import back from "../assets/back.png";
import { Col, Row, Button, Form, Input } from "antd";
import "./ForgotPassword.css";
import { Link } from "react-router-dom";
import EmailAccountLoginController from "../../email-account-registration/src/EmailAccountRegistrationController.web";
import type { FormInstance } from 'antd/es/form';
import { ToastMessage } from "../../../components/src/Toast";


class ForgotPassword extends EmailAccountLoginController {
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}
    async componentDidMount() {
		this.getDynamicTextAndImageForForgot();
	}

	render(): React.ReactNode {
		return (
			<>
				<Row gutter={12} style={{height:'100%'}}>
					<Col xs={24} sm={12}>
						<div className="forgot-centered-content">
							<img style={{ width: "72%", marginBottom: '56px' }} src={rehab} />
							<img
								style={{ width: "88%", marginBottom: '45px' }}
								src={this.state.image_data}
								alt="rehab"
							/>
							<p className="forgot-headline-1">{this.state.title}</p>

							<p className="forgot-hi-there">
								{this.state.description}
							</p>
						</div>
					</Col>
					<Col xs={24} sm={12}>
						<div id="forgot" className="forgot-section">
                            <Link to="/login">
                            <div style={{display: 'flex',marginLeft: "18px", alignItems: "baseline"}}>
                                <img src={back}  style={{height: '12px', width: '18px'}}/>
                                <p className="forgot-back">Back</p>
                            </div>
                            </Link> 
                            <p className="forgot-headline-2">Forgot Password?</p>
                            <Form
                                ref={this.formRef}
                                layout="vertical"
                                initialValues={{}}
                                className="form"
                                style={{ padding: "20px" }}
                            >
                                    <Row gutter={12}>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="Email"
                                                name="email"
                                                className="bold-label"
                                            >
                                                <Input
                                                    className={this.state.forgotPasswordErrorMessage ? "input-error" : ""}
                                                    type="email"
                                                    placeholder="Your email"
                                                    />
                                                   
                                            </Form.Item>
                                            {this.state.forgotPasswordErrorMessage ? (<><span  style={{color:'#DC2626', float:'left', textAlign: 'left'}}>{this.state.forgotPasswordErrorMessage}</span></>): (<></>)}

                                        </Col>

                                        <Col xs={24}>
                                            
                                            <Button
                                                data-test-id="forgot_button"
                                                onClick={() => this.onForgotPassword(this.formRef.current.getFieldsValue())}
                                                htmlType="submit"
                                                type="primary"
                                                loading={this.state.loadingForgotPassword}
                                                style={{
                                                    background: "#830065",
                                                    borderColor: "#830065",
                                                    borderRadius: "5px",
                                                    marginTop: "10px",
                                                    height: "45px",
                                                    width: "100%",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Send Password Reset link
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                 { this.state.showToast && <ToastMessage
                                    data-test-id = "toast-message"
                                    message = {this.state.toastMessage} 
                                    visible = {this.state.showToast}
                                />}
                        </div>
					</Col>
                    
				</Row>
			</>
		);
	}
}

export default ForgotPassword;
// Customizable Area End
