// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from "react";
import rehab_image from "../assets/rehab.png";
import otp_image from "../assets/otp.png";
import back from "../assets/back.png";
import { Col, Row, Button, Spin, Modal } from "antd";
import "./EmailAccountRegistration.css";
import { Link, Redirect } from "react-router-dom";

import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
import TimerVerify from "./TimerVerify";

interface OTPControllerProps {
  someProp: string;
  anotherProp: number;
}

class OTP extends EmailAccountRegistrationController {
  constructor(props: OTPControllerProps) {
    super(props);
    this.generateDeviceId();
  }
  async componentDidMount() {
		this.getDynamicTextAndImageForOtp();
	}

  renderModalsForResendSucess() {
    return (
        <Modal
          className="cookies-modal"
          data-test-id="resend-modal"
          open={this.state.resendModel}
          onCancel={this.hideResendSucessModel}
          footer={
            <div style={{ display: "flex", justifyContent: "right", padding: '0 22px 22px 22px' }} >

              <Button
                style={{
                  height: "56px", width: "120px",
                  background: "#830065",borderColor: "#830065",
                  borderRadius: "8px",color: "white",
                  fontSize: '16px',fontWeight: '700'
                }}
                onClick={this.hideResendSucessModel} >Okay</Button>
            </div>
          }
        >
          <div style={{
            padding: '40px 25px 20px 25px', fontSize: '16px',
            fontWeight: '500', marginTop: '10px'
          }}>
            OTP sent successfully
          </div>

        </Modal>
    );
  }
  onClickResend = () => {
    if (this.state.resendFlag) {
       this.resendOTP(localStorage.getItem("phone"));
       this.updateResendFlag();
       this.state.digit1 = '';
       this.state.digit2 = '';
       this.state.digit3 = '';
       this.state.digit4 = '';
       this.state.digit5 = '';
    }
  };
  updateResendFlag = async () => {
    if (this.state.resendFlag) {
      this.setState({ minutes: 0, seconds: 59 });
    }
    this.setState({ resendFlag: !this.state.resendFlag });
  };

  decrementTimer = () => {
    this.setState({ seconds: this.state.seconds - 1 });
  };
  decrementMinute = () => {
    this.setState({ minutes: this.state.minutes - 1, seconds: 59 })
  }

  render(): React.ReactNode {
    const userType = localStorage.getItem('userType');
    const phone = this.state.phonenum;
        if (this.state.redirectToDashboard) {
            return (userType === 'client') ? <Redirect to='/client-dashboard' /> : <Redirect to='/dashboard' />;
        }
    return (
      <>
        <Row gutter={12} style={{ height: '100%' }}>
          <Col sm={12} xs={24} style={{ paddingBottom: '120px', background: "rgba(251,241,249,255)" }}>
            <div className="centered-content-signup"> 
            <img
            style={{
              width: "100%",
              maxWidth: "435px", 
              height: "auto", 
              marginTop: "120px",
              marginBottom: "44px"
            }}
            src={rehab_image}
            alt="Rehab"
          />
              <img
                style={{
                  width: "100%",
                  maxWidth: "523px",
                  height: "auto",
                  marginBottom: "70px"
                }}
                src={this.state.image_data}
                alt="Rehab"
              />
              <p
                className="headline-1"
                style={{
                  margin: "auto", textAlign: "center",
                  width: "423px",
                  height: "44px", marginBottom: "16px",
                  lineHeight: 1
                }}
              >
                {this.state.title}
              </p>
              <p
                className="feature-content"
                style={{
                  width: "423px",  margin: "auto",
                  textAlign: "center", height: "52px"
                }}
              >
               {this.state.description}
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div id="otp" className="">
              
                <div
                  style={{
                    marginBottom: "40px", marginTop: "287px",
                    marginLeft: "143px", display: "flex",
                    cursor:'pointer'
                  }}
                  onClick={() => history.back()}
                >
                </div>
              
              <p className="otp-headline-2">Two-Factor Authentication</p>
              <p className="bodytext-3">
                Enter the 5-digit code that we sent to your <br /> mobile{" "}
                {
                  this.state.phonenum &&
                <strong style={{ color: "#830065", fontWeight: "600" }}>
                  {`+${phone.substring(0, 2)} ${phone.substring(2)}`}
                </strong>
                }
              </p>
              <div className="otp-container">
                <input
                  id="digit1"
                  className={
                    this.state.otpErrorMessage ? "otp-input-error" : "otp-input"
                  }
                  type="text"
                  ref="digit1"
                  value={this.state.digit1}
                  onChange={event => this.handleInputChange(event, "digit1")}
                  maxLength="1"
                />
                <input
                  id="digit2"
                  className={
                    this.state.otpErrorMessage ? "otp-input-error" : "otp-input"
                  }
                  type="text"
                  ref="digit2"
                  value={this.state.digit2}
                  onChange={event => this.handleInputChange(event, "digit2")}
                  maxLength="1"
                />
                <input
                  id="digit3"
                  className={
                    this.state.otpErrorMessage ? "otp-input-error" : "otp-input"
                  }
                  type="text"
                  ref="digit3"
                  value={this.state.digit3}
                  onChange={event => this.handleInputChange(event, "digit3")}
                  maxLength="1"
                />
                <input
                  id="digit4"
                  className={
                    this.state.otpErrorMessage ? "otp-input-error" : "otp-input"
                  }
                  type="text"
                  ref="digit4"
                  value={this.state.digit4}
                  onChange={event => this.handleInputChange(event, "digit4")}
                  maxLength="1"
                />
                <input
                  id="digit5"
                  className={
                    this.state.otpErrorMessage ? "otp-input-error" : "otp-input"
                  }
                  type="text"
                  ref="digit5"
                  value={this.state.digit5}
                  onChange={event => this.handleInputChange(event, "digit5")}
                  maxLength="1"
                />
              </div>
              {this.state.otpErrorMessage && (
                <p
                  className="otp-error-msg"
                  style={{
                    color: "#DC2626",textAlign: "left",
                    marginLeft: "143px",marginTop: 0
                  }}
                  id="loginInErrorMsg"
                >
                  {this.state.otpErrorMessage}
                </p>
              )}
              <div style={{ marginTop: '20px' , width: "376px",marginLeft: "150px"}}>
                <TimerVerify
                  decrementMinute={this.decrementMinute}
                  decrementTimer={this.decrementTimer}
                  updateResendFlag={this.updateResendFlag}
                  resendFlag={this.state.resendFlag}
                  seconds={this.state.seconds}
                  minutes={this.state.minutes}
                />
              </div>

              <br />
              <Col xs={24}>
                {this.state.loadingOTP ? (
                  <div style={{marginTop:"-20%",width:"100%",height:"10%", display:"fixed",position:"absolute", justifyContent:"center",alignItems:"center"}}>
                  <Spin id="otp-spin"  />
                  </div>
                ) : (
                  <Button
                    data-testid="verify"
                    onClick={() => localStorage.getItem("newDevice")=== "true" ? this.onClickVerifyDevice() : this.onClickVerify()
                    }
                    type="primary"
                    style={{
                      background: this.getBackground(), borderColor: this.getBackground(),
                      borderRadius: "8px",marginBottom: "20px",
                      height: "56px",width: "376px",
                      color: "white",fontWeight: "bold",
                      marginTop: "6px", marginLeft: "136px",
                      fontSize:"16px",
                      cursor: this.getverifyCursor()
                    }}
                  >
                    Verify
                  </Button>
                )}
              </Col>
              <Col xs={24}>
                <Button
                  data-testid="resend"
                  htmlType="submit"
                  type="text"
                  onClick={() => {
                    this.onClickResend();
                    this.setState({ otpErrorMessage: null })
                  }}
                  style={{
                    marginBottom: "20px",height: "45px",
                    width: "378px", color: this.getColor(),
                    fontWeight: "bold", fontSize: "16px",
                    marginLeft: "136px", cursor:this.getCursor(),
                  }}
                >
                  Resend
                </Button>
              </Col>
            </div>
          </Col>
        </Row>
        {this.renderModalsForResendSucess()}
      </>
    );
  }
}

export default OTP;
// Customizable Area End