// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FailedToast, SuccessMessage, FailedToastCenter } from "../../../components/src/Toast";

export const configJSON = require("./config");

export interface Props {
	navigation: any;
	id: string;
	match: any;
}

interface S {
	clientData: any,
	loadingClientData: boolean,
	searchText: string,
	clientStatus:string | null,
	document?: File | Blob,
	singleFileData?: any,
	fileData: any,
	loadingFileData: boolean,
	showDocument: boolean,
	documentUrl: string,
	title: string,
	entry: string,
	created_at: any,
	popupVisible: string,
	loadingData: boolean,
	redirectToEntriesScreen: boolean,
	loadingDateWiseEntries: boolean,
	dateWiseAllData: any,
	dateWiseSelectedData: any,
	loading: boolean,
	showDeleteModel:boolean,
	fileID: number,
	deleteFileLoader: boolean,
	fileName: string,
	showReplaceModal:boolean,
	replaceFileLoader: boolean,
}

interface SS {
	id: any;
}



export default class UserLibraryController extends BlockComponent<Props, S, SS> {
	
	apiResetSearchClientFormCallId: string = "";
	apiResetFilePostCallId: string = "";
	apiResetGetFileFormCallId: string = "";
	apiDeleteFilePostCallId: string = "";
	apiResetGetSingleFileFormCallId: string = "";
	apiResetUpdateCaseManagementeFormCallId: string = "";
	apiResetCreateRehabJournalCallId: string = "";
	apiResetDateWiseGetEntriesFormCallId: string = "";
	apiReplaceFilePostCallId: string = "";

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
		];
		this.state = {
			clientData: [],
			loadingClientData: false,
			searchText: '',
			fileData: [],
			loadingFileData: false,
			showDocument: false,
			documentUrl: '',
			title: '',
			clientStatus:localStorage.getItem("ClientStatus"),
			entry: '',
			popupVisible: '',
			created_at: '',
			loadingData: true,
			redirectToEntriesScreen: false,
			loadingDateWiseEntries: true,
			dateWiseAllData: [],
			dateWiseSelectedData: null,
			loading: false,
			showDeleteModel: false,
			fileID: 0,
			deleteFileLoader: false,
			fileName: "",
			showReplaceModal: false,
			replaceFileLoader: false
			
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async receive(from: string, message: Message) {
		this.receiveSearchClient(message);
		this.receiveGetFiles(message);
		this.receiveCreateFile(message);
		this.receiveDeleteFile(message);
		this.receiveGetSingleFile(message);
		this.receiveUpdateFile(message);
		this.receiveCreateRehabJournal(message);
		this.receiveDateWiseEntries(message);
		this.receiveReplaceFile(message);
	}

	
	async receiveSearchClient(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetSearchClientFormCallId &&
            this.apiResetSearchClientFormCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({clientData: responseJson?.clients ? responseJson.clients.map( (e: any) => (
				{first_name:e.first_name, 
					full_name:e.full_name,
					status:e.status,
				id: e.id}
			)) : [], loadingClientData: false});
            
          }
	}

    async receiveCreateFile(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetFilePostCallId &&
			this.apiResetFilePostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
               
			 if(responseJson && responseJson.data){
				this.setState({loading: false});
				this.getFiles();
				SuccessMessage("File created successfully")
			  }else if(responseJson && responseJson.error){
				this.setState({loading: false, showReplaceModal: true})
			  }
		}
	}
	async receiveGetFiles(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetFileFormCallId &&
            this.apiResetGetFileFormCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({fileData: responseJson.data, loadingFileData: false});

          }
	}

	async receiveDeleteFile(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiDeleteFilePostCallId &&
			this.apiDeleteFilePostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	
			 if(responseJson && responseJson.message){
				this.setState({deleteFileLoader: false, showDeleteModel: false})
				SuccessMessage("File deleted successfully");
				this.getFiles();
			  }else{
				this.setState({deleteFileLoader: false, showDeleteModel: false})
				FailedToastCenter(responseJson.errors);
			  }
		}
	}

	receiveGetSingleFile(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetGetSingleFileFormCallId &&
			this.apiResetGetSingleFileFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.data) {
				this.setState({
					loadingData: false,
					title: responseJson.data.attributes.title,
					entry: responseJson.data.attributes.entry,
					created_at: responseJson.data.attributes.created_at
				});
			}


		}
	}

	async receiveUpdateFile(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetUpdateCaseManagementeFormCallId &&
			this.apiResetUpdateCaseManagementeFormCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
               
			 if(responseJson && responseJson.data){
				SuccessMessage("Note updated successfully");
			  }
		}
	}
	async receiveCreateRehabJournal(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetCreateRehabJournalCallId &&
			this.apiResetCreateRehabJournalCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
               
			 if(responseJson && responseJson.data){
				SuccessMessage("Entry Added Successfully")
				this.getFiles();
				this.setState({ redirectToEntriesScreen: true})
			  }else if(responseJson && responseJson.errors){
				FailedToastCenter(responseJson?.errors);
			  }else{
				return;
			  }
		}
	}

	receiveDateWiseEntries(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetDateWiseGetEntriesFormCallId &&
			this.apiResetDateWiseGetEntriesFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.data && responseJson.data.length > 0) {
				this.setState({dateWiseAllData: responseJson.data, loadingDateWiseEntries: false, dateWiseSelectedData: responseJson.data[0]});
			}else{
				this.setState({dateWiseAllData: [], loadingDateWiseEntries: false, dateWiseSelectedData: null});
			}


		}
	}

	async receiveReplaceFile(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiReplaceFilePostCallId &&
			this.apiReplaceFilePostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	
			 if(responseJson && responseJson.message){
				this.setState({replaceFileLoader: false, showReplaceModal: false})
				SuccessMessage("File replaced successfully");
				this.getFiles();
			  }else{
				this.setState({replaceFileLoader: false, showReplaceModal: false})
				FailedToastCenter(responseJson.errors);
			  }
		}
	}
	searchClient(clientName?: string): boolean {
		clientName = clientName && clientName !== "" ? clientName :  " ";
		this.setState({searchText: clientName ?? ''});
        const token = localStorage.getItem("token");
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
            "token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetSearchClientFormCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.searchClientApiEnnPoint}?search=${clientName}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeGet
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	createFile(folderType: string, clientId: string, file: File | null, title: string, entry: string): boolean {
		this.setState({ loading: true });
		const token = localStorage.getItem('token');
		const header = {
			"token": token
		};

		let form = new FormData();
		form.append('folder_type', folderType);
		form.append('client_id', clientId);
		if(file){
			form.append('file', file);
		}
		if(title){
			form.append('title', title);
			form.append('entry', entry);
		}
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetFilePostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.createFileApiEnnPoint}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			form
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	getFiles = () => {
        const token = localStorage.getItem("token");
        this.setState({loadingFileData: true})
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetFileFormCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		 `${configJSON.getFileApiEndPoint}?client_id=${this.getUrlParameter("id")}&folder_type=${this.getUrlParameter("folderType")}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.apiMethodTypeGet
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };


	  deleteFile(fileId: string): boolean {
		this.setState({deleteFileLoader: true})
		const token = localStorage.getItem('token');
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};

		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiDeleteFilePostCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.deleteFileApiEndPoint}/${fileId}`
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.apiDeleteMethodTypeAddDetail
		);
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	  }

	  getSingleFile = (id: any) => {
		this.setState({loadingData: true});
		const token = localStorage.getItem('token');
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetGetSingleFileFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.deleteFileApiEndPoint}/${id}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeGet
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	updateNote(id: string, title: string, entry: string): boolean {
		const token = localStorage.getItem('token');
		const header = {
			"token": token
		};

		let form = new FormData();
		form.append('title', title);
		form.append('entry', entry);
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetUpdateCaseManagementeFormCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.createFileApiEnnPoint}/${id}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			form
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiUpdateMethodTypeGet
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	createRehabJournal(folderType: string, clientId: string, title: string, entry: string, entry_date: any): boolean {
		const token = localStorage.getItem('token');
		const header = {
			"token": token
		};

		let form = new FormData();
		form.append('folder_type', folderType);
		form.append('client_id', clientId);
		form.append('title', title);
		form.append('entry', entry);
		form.append('entry_date', entry_date);
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetCreateRehabJournalCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.createRehabJournalApiEndPoint}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			form
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	dateWiseGetEntries = (date: any) => {
		this.setState({loadingDateWiseEntries: true});
		const token = localStorage.getItem('token');
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetDateWiseGetEntriesFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.dateWiseEntriesApiEndPoint}?search_date=${date}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeGet
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	replaceFile(file: File | null, fileId: any, folderType: string): boolean {
		this.setState({ replaceFileLoader: true });
		const token = localStorage.getItem('token');
		const header = {
			"token": token
		};

		let form = new FormData();
		form.append('client_id', fileId);
		form.append('folder_type', folderType);
		if(file){
			form.append('file', file);
		}
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiReplaceFilePostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.replaceFileApiEndPoint}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			form
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	  handleShowDocument(value: string){
		this.setState({singleFileData: value, showDocument: true})
	  }

	  handleHideDocument = () =>{
		const videoElement = document.getElementById('videoElement') as HTMLVideoElement;
		if (videoElement?.pause) {
			videoElement.pause();
		  }
		this.setState({showDocument: false, singleFileData: null})
	  }

	
	  getUrlParameter(name: any) {
		let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		let results = regex.exec(window?.location?.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}

	 handleDownload = (fileUrl: string, fileName: string ) => {
		this.setState({ loading: true });
		fetch(fileUrl)
		  .then(response => response.blob())
		  .then(blob => {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.parentNode?.removeChild(link);
			SuccessMessage("File downloaded successfully !");
		  })
		  .catch(error => {
			FailedToast("Can not download the file.");
		  }).finally(() => {
			this.setState({ loading: false });
		  });
	  };

	hideDeleteModel = () => {
		this.setState({ showDeleteModel: false, fileID: 0 })
	}
	showDeleteModel = (id: any, name: string) => {
		if(this.state.clientStatus !== "closed"){
		this.setState({ showDeleteModel: true, fileID: id, fileName: name })
		}
	}

	 handleDocumentAction(document_name: any, documents: any, handleShowDocument: any, handleHideDocument: any) {
		const fileExtension = document_name.split('.').pop().toLowerCase();
	
		if (['jpg', 'jpeg', 'png', 'gif', 'mp4'].includes(fileExtension)) {
			handleShowDocument();
		} else if (fileExtension === 'pdf') {
			window.open(documents, '_blank');
		} else {
			handleHideDocument();
		}
	}

	hideReplaceModel = () => {
		this.setState({ showReplaceModal: false, fileID: 0 })
	}
	showReplaceModal = (id: any, name: string) => {
		this.setState({ showReplaceModal: true, fileID: id, fileName: name })
	}
	
}
// Customizable Area End