
// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import { Avatar, Button, Col, Form, Input, Layout, Row, Modal, Spin, Divider, Upload, Popover, message,Space,Badge } from 'antd';
import React from 'react';
const { Header } = Layout;
import {
    PlusOutlined,
    UserOutlined
  } from '@ant-design/icons';
import './NavHeader.css';
import EmailAccountRegistrationController from '../../blocks/email-account-registration/src/EmailAccountRegistrationController.web';
import type { RcFile } from 'antd/es/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import profileIcon from "./profile.png";
import {styled } from "@material-ui/core/styles";
import _ from 'lodash';
import moreIcon from "./more.png";
import NoImage from './Noimage.png'
import { Tag, Tooltip } from 'antd';
import { SuccessMessage } from './Toast';
import mood from './mood.png';
import bell from './bell.png';
import { happy, positive, sad } from '../../blocks/dashboard/src/assets';
import ErrorIcon from '@material-ui/icons/Error';
import InfiniteScroll from 'react-infinite-scroll-component';
class NavHeader  extends EmailAccountRegistrationController {
	constructor(props: any) {
		super(props);
  }

  async componentDidMount(){
    this.getProfile();
    this.getClientProfile()
    setInterval(()=>{
			this.getNotifications()
		 },300000)
  }

  getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  uploadProfilePicture = (info: UploadRequestOption) => {
    this.setState({profileImage: info.file as File})
    this.getBase64(info.file as RcFile, url => {
      this.setState({profileImageUrl: url});
    });
  }

  setHobbies = (hobbies: string[]) => {
      this.setState({hobbies: hobbies})
  }
  showCreateMoodModal = () => {
		this.setState({showAddMood: true})
	}
  showUpdateMoodModal = () => {
		this.setState({showUpdateMood: true})
	}
  handleMoodDivClose = () => {
    this.setState({ isVisible: false });
  };
	moodHandleCancel = () => {
		this.setState({showAddMood: false, selectedMood: '' });
	}
  moodUpdateHandleCancel = () => {
		this.setState({showUpdateMood: false, selectedMood: '' });
	}
	handleSelectMood = (mood: string) => {
        this.setState({ selectedMood: mood });
    };

	handleAddMood = () => {
        if (this.state.selectedMood) {
            this.createMood(this.state.selectedMood);
            this.setState({ showAddMood: false, selectedMood: '' });
        }
    };
    handleUpdateMood = () => {
      if (this.state.selectedMood) {
          this.updateMood(this.state.selectedMood);
          this.setState({ showUpdateMood: false, selectedMood: '' });
      }
  };


  renderAddMoodModal() {
    
    return (
        <Modal
            className="mood-modal"
            id="mood-modal"
            title={
                <div style={{ textAlign: 'center', marginLeft: '-120px' }}>
                    <p className="mood-modal-header">
                        Update Mood Score
                    </p>
                </div>
            }
            open={this.state.showAddMood}
            onCancel={this.moodHandleCancel}
            closable={false}
            footer={null}
            width={790}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
        >
            <div className="mood-selection">
                <div
                    className={`mood-option ${this.state.selectedMood === 'not_feeling_good' ? 'selected' : ''}`}
                    onClick={() => this.setState({ selectedMood: 'not_feeling_good' })}
                >
                    <img src={sad} alt="not feeling good" />
                    <p className="mood-title">Not feeling good</p>
                </div>
                <div
                    className={`mood-option ${this.state.selectedMood === 'neutral' ? 'selected' : ''}`}
                    onClick={() => this.setState({ selectedMood: 'neutral' })}
                >
                    <img src={positive} alt="neutral" />
                    <p className="mood-title">Neutral</p>
                </div>
                <div
                    className={`mood-option ${this.state.selectedMood === 'positive' ? 'selected' : ''}`}
                    onClick={() => this.setState({ selectedMood: 'positive' })}
                >
                    <img src={happy} alt="positive" />
                    <p className="mood-title">Positive</p>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', padding: "20px" }}>
                        <Button
                            data-testid="cancel-model"
                            type="primary"
                            onClick={this.moodHandleCancel}
                            style={{
                                background: "#ffff",
                                borderColor: "#ffff",
                                height: "56px",
                                width: '120px',
                                color: "#830065",
                                fontWeight: "bold",
                                fontSize: "14px",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                          data-testid="submit-mood"
                          onClick={this.handleAddMood}
                          className="custom-button"
                          loading={this.state.loadingCreateMood}
                          htmlType="submit"
                          type="primary"
                      >
                          Add
                      </Button>
                </div>
        </Modal>
    );
}
renderUpdateMoodModal() {
    
  return (
      <Modal
          id="update-mood-modal"
          className="mood-modal"
          title={
              <div className='update-mood-div'>
                  <p className="mood-modal-header">Update Mood Score</p>
              </div>
          }
          closable={false}
          footer={null}
          width={790}
          open={this.state.showUpdateMood}
          onCancel={this.moodUpdateHandleCancel}
          okButtonProps={{
              style: {
                  display: "none",
              },
          }}
          cancelButtonProps={{
              style: {
                  display: "none",
              },
          }}
      >
          <div className="mood-selection">
              <div
                className={`mood-option ${this.state.selectedMood === 'not_feeling_good' ? 'selected' : ''}`}
                onClick={() => this.setState({ selectedMood: 'not_feeling_good' })}
              >
                  <img src={sad} alt="not feeling good" />
                  <p className="mood-title">Not feeling good</p>
              </div>
              <div
                className={`mood-option ${this.state.selectedMood === 'neutral' ? 'selected' : ''}`}
                onClick={() => this.setState({ selectedMood: 'neutral' })}
              >
                  <img src={positive} alt="neutral" />
                  <p className="mood-title">Neutral</p>
              </div>
              <div
                className={`mood-option ${this.state.selectedMood === 'positive' ? 'selected' : ''}`}
                onClick={() => this.setState({ selectedMood: 'positive' })}
              >
                  <img src={happy} alt="positive" />
                  <p className="mood-title">Positive</p>
              </div>
          </div>
          <div className='update-button-div'>
              <Button
                  data-testid="update-cancel-model"
                  type="primary"
                  onClick={this.moodUpdateHandleCancel}
                  style={{
                      background: "#ffff",
                      borderColor: "#ffff",
                      height: "56px",
                      width: '120px',
                      color: "#830065",
                      fontWeight: "bold",
                      fontSize: "14px",
                  }}
              >
                  Cancel
              </Button>
              <Button
                data-testid="update-mood"
                onClick={this.handleUpdateMood}
                className="custom-button"
                loading={this.state.loadingUpdateMood}
                htmlType="submit"
                type="primary"
            >
                Add
            </Button>
          </div>
      </Modal>
  );
}

	render(): React.ReactNode {
    const userType = localStorage.getItem('userType');
    
		return (
     
      <>
        <div className='header-right-side'>
                {userType === "client" && <img src={mood} alt="icon" className='mood-image' />}
                {userType !== "case_controller" && userType !== "case_manager" && userType !== "assistant_case_manager" && 
                userType !== "solicitor" &&  userType !== "guest" &&
                <div className="mood-container">
                    <span data-testid="add-mood" onClick={this.showCreateMoodModal} className="mood-text">Mood</span>
                    {this.state.isVisible && 
                          <div className="mood-update-container">
                            <span onClick={this.showUpdateMoodModal} className="mood-update-text">Update mood score here</span>
                            <button onClick={this.handleMoodDivClose} className="close-button">X</button>
                          </div>}
                       
                    
                </div>
               }
          <div >
          <Space size="small">
            <Badge dot={this.state.showbellicon} className="custom-badge">
            <img src={bell} alt="icon" className='bell-icon'  onClick={()=>this.showNotficationModal(userType)} />
            </Badge>
           </Space>
            <CustomModal
              className="notification-modal"
              id="notification-modal"
              title={<div className="notification-head" >
                <h6 className="notification-title" >
                  Notifications
                </h6>
              {this.state.showNotifications.length > 0 && 
                <span className='markRead' onClick={this.readAll}>Mark as all read</span>
              }

              </div>}
              open={this.state.notification}
              closable={false}
              onCancel={this.closeNotificationMoal}
              maskClosable={true}
              footer={null}
              okButtonProps={{
                style: {
                  display: "none",
                },
              }}
              cancelButtonProps={{
                style: {
                  display: "none",
                },
              }}
            >
              {
                 this.state.showNotifications.length > 0 ?
                  <div className='notificationContainer'>
                    <InfiniteScroll
                      dataLength={this.state.showNotifications.length}
                      next={this.fetchMoreData}
                      hasMore={this.state.moreData}
                      height={450}
                      loader={<span style={{position:"absolute",bottom:"22px",right:"50%"}}> <Spin spinning={true} /></span>}
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>No more notifications</b>
                        </p>
                      }

                    >

                      {
                        this.state.showNotifications.map((item) => (

                          userType === "case_manager" ?
                            <>
                              <div onClick={() => this.onNotificationClick(item.id)} key={item.id} className={item.attributes.is_manager_read ? "notify2" : 'notify'}>
                                <div style={{ padding: "0 10px" }} > <ErrorIcon className={item.attributes.is_manager_read ? 'editerroricon2' : 'editerroricon'} /> </div>
                                <div>
                                  <div className='notification-title2'>{item.attributes.message}</div>
                                  <div className='notification-body'>{item.attributes.reminder_type === "Appointment" && "You have an upcoming appointment"}</div>
                                  <div className='notification-time'>{`${item.attributes.created_at} ago`}</div>
                                </div>
                              </div>
                              <Divider className='divider' />
                            </>
                            :
                            <>
                              <div className={item.attributes.is_client_read ? "notify2" : 'notify'}>
                                <div style={{ padding: "0 10px" }} > <ErrorIcon className={item.attributes.is_client_read ? 'editerroricon2' : 'editerroricon'} /> </div>
                                <div>
                                  <div className='notification-title2'>{item.attributes.message}</div>
                                  <div className='notification-body'>{item.attributes.reminder_type === "Appointment" && "You have an upcoming appointment"}</div>
                                  <div className='notification-time'>{`${item.attributes.created_at} ago`}</div>
                                </div>
                              </div>
                              <Divider className='divider' />

                            </>

                        ))
                      }
                    </InfiniteScroll>

                  </div>
                 :
                 <div className='emptyNotification'>No Notifications</div>
              }

            </CustomModal>

          </div>

         
                <p className='profile-name'>{_.capitalize(_.head(_.words(this.state.full_name)))}</p>

                {this.state.loadingProfileData ? (
                    <div className='spin-placement-image'>
                        <Spin />
                    </div>
                ) : (
                    <Avatar 
                        className='image-style'
                        onClick={() => this.showProfileModal()} 
                        style={{ cursor: 'pointer' }}  
                        icon={this.state.profileImageUrl === null ? <img src={NoImage} className='image-style'/> : <img src={this.state.profileImageUrl} alt="user" />}
                    />
                )}
                
        </div>
        <Modal
            className='profile-modal'
            id="tncModal"
            title={<p className="profile-header">Edit Profile</p>}
            open={this.state.showProfile}
            onCancel={this.profileHandleCancel}
            footer={
                null
            }
            width={460}
            >
              {
                this.state.loadingProfileData ? <Spin/> :
                <ProfileModalContent 
                  onUpdateProfile = {this.onUpdateProfile} 
                  profileHandleCancel = {this.profileHandleCancel}
                  uploadProfilePicture = {this.uploadProfilePicture}
                  deleteProfileImage = {() => this.deleteProfileImage()}
                  deleteProfileImage = {() => this.deleteProfileImage()}
                  profileImageUrl = {this.state.profileImageUrl}
                  setHobbies = {this.setHobbies}
                  loadingstate={this.state.loadingUpdateProfile}
                  user = {{
                    full_name: this.state.full_name,
                    email: this.state.email,
                    full_phone_number: this.state.full_phone_number,
                    office_phone_no: this.state.office_phone_no
                  }}
                />
              }
        </Modal>
        {this.renderAddMoodModal()}
        {this.renderUpdateMoodModal()}
      </>
		);
	}
}

class ProfileModalContent extends NavHeader {

    constructor(props: any) {
      super(props);
    }

    uploadRef = React.createRef();

    getBase64 = (img: RcFile, callback: (url: string) => void) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result as string));
      reader.readAsDataURL(img);
    };
      
    beforeUpload = (file: RcFile) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      return isJpgOrPng;
      };
  
    uploadProfilePicture = (info: UploadRequestOption) => {
      this.setState({profileImage: info.file as File})
      this.getBase64(info.file as RcFile, url => {
        this.setState({profileImageUrl: url});
      });
    }
      showImageModal = () => {
        this.getProfile();
        this.setState({
          showImage: true,
        });
    
      }
      imageHandleCancel = (event: any) => {
        this.setState({
          showImage: false,
        });
      }
      verifyEmailModal = () => {
        this.setState({
          verifyEmailPopup: true,
        });
    
      }
      verifyEmailPopupHandleCancle = (event: any) => {
        this.setState({
          verifyEmailPopup: false,
        });
      }

      render() {
        const uploadButton = (
          <div data-test-id="upload-button">
            <img src={profileIcon} style={{height: '55px', width: '58px', marginBottom: '7px' }} />
            <p className='upload-title'>Upload</p>
          </div>
        );
        const userType = localStorage.getItem('userType');
      
        const validateEmail = (_, value) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!value || !emailRegex.test(value)) {
            return Promise.reject(new Error('Please enter a valid email address!'));
          } else {
            return Promise.resolve();
          }
        };
      
        const validatePhoneNumber = (_, value) => {
          const phoneNumberPattern = /^\d+$/;
          if (!value || !phoneNumberPattern.test(value)) {
            return Promise.reject(new Error('Please enter a valid phone number!'));
          }
          return Promise.resolve();
        };
      
        return (
          <div
            style={{ width: '100%', paddingLeft: '13px', zIndex: 10 }}
            onClick={() => {
              this.setState({ popupVisible: false });
            }}
          >
            <div style={{ position: 'relative' }}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={this.props?.uploadProfilePicture ?? this.uploadProfilePicture}
              >
                <div ref={e => (this.uploadRef = e)}>
                  {!this.props?.profileImageUrl ? (
                    uploadButton
                  ) : (
                    <div
                      style={{
                        position: 'relative',
                        width: '152px',
                        height: '152px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={this.props?.profileImageUrl ?? this.state.profileImageUrl}
                        alt="no image"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </Upload>
              {(this.state.profileImageUrl || this.props.profileImageUrl) && (
                <div
                  className="popover-div"
                  data-test-id="pop"
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    this.setState({ popupVisible: true });
                    e.stopPropagation();
                  }}
                >
                  <img src={moreIcon} className="more-icon" />
                </div>
              )}
              {this.state.popupVisible && (
                <div className="profile-image-menu">
                  <p
                    data-test-id="show-image"
                    onClick={() => {
                      this.showImageModal();
                      this.setState({ popupVisible: false });
                    }}
                    className="menu-text"
                  >
                    View Photo
                  </p>
                  <p
                    data-test-id="delete-image"
                    className="menu-text"
                    onClick={() => {
                      this.props?.deleteProfileImage ? this.props.deleteProfileImage() : this.deleteProfileImage();
                      this.setState({ popupVisible: false });
                    }}
                  >
                    Delete Photo
                  </p>
                  <p
                    className="menu-text"
                    onClick={() => {
                      this.uploadRef.click();
                    }}
                  >
                    Upload New Profile Photo
                  </p>
                </div>
              )}
            </div>
      
            {this.state.loadingProfileData ? (
              <div className="spin-placement">
                <Spin />
              </div>
            ) : (
              <Form
                layout="vertical"
                initialValues={this.props?.user}
                onFinish={(values) => {
                  let obj = { ...values };
                  delete obj.office_phone_no;
                  const allFieldsFilled = Object.values(obj).every(value => value !== undefined && value !== '');
                  if (!allFieldsFilled) {
                    message.error("Fields can't be blank");
                    return;
                  }
      
                  if (this.state.email !== values.email) {
                    this.setState({ verifyEmailPopup: true, profileUpdateData: values });
                    return;
                  }
                  this.props?.onUpdateProfile ? this.props.onUpdateProfile(values) : this.onUpdateProfile(values);
                }}
                className="form"
                style={{ padding: '20px' }}
              >
                <Row gutter={12}>
                  <Col xs={24}>
                    <Form.Item
                      label="Full Name"
                      name="full_name"
                      className={userType !== 'client' ? 'disable-profile-label' : 'profile-label'}
                      rules={[{ required: true, message: 'Please enter your full name' }]}
                    >
                      <Input disabled={userType !== 'client'} />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="full_phone_number"
                      label="Mob. Number"
                      className={userType !== 'client' ? 'disable-profile-label' : 'profile-label'}
                      rules={[{ validator: validatePhoneNumber }]}
                    >
                      <Input disabled={userType !== 'client'} />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Email ID"
                      name="email"
                      className={userType !== 'client' ? 'disable-profile-label' : 'profile-label'}
                      rules={[{ validator: validateEmail }]}
                    >
                      <Input className="specific-input" disabled={userType !== 'client'} />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="office_phone_no"
                      label="Office Phone No. (Optional)"
                      className="profile-label"
                    >
                      <Input placeholder="Enter Office Phone No." maxLength={13} />
                    </Form.Item>
                  </Col>
                  {userType === 'client' && (
                    <Col xs={24}>
                      <p className="hobbies-title">Hobbies(Optional)</p>
                      <EditableTagGroup tags={this.state.hobbies} setTags={this.props.setHobbies} />
                    </Col>
                  )}
                  
                  <Divider className="profile-divider" />
                  <Col xs={24}>
                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                      <Button
                        type="text"
                        style={{
                          color: '#64748B',
                          fontSize: '16px',
                          fontWeight: '700',
                          marginRight: '14px',
                          height: '56px',
                          width: '120px',
                        }}
                        onClick={this.props?.profileHandleCancel ?? this.profileHandleCancel}
                      >
                        Cancel
                      </Button>
      
                      <Button
                        htmlType="submit"
                        loading={this.props.loadingstate}
                        style={{
                          background: '#830065',
                          borderColor: '#830065',
                          borderRadius: '8px',
                          color: 'white',
                          height: '56px',
                          width: '120px',
                          fontSize: '16px',
                          fontWeight: '700',
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
      
            <Modal
              className="image-modal"
              id="tncModal"
              title={<p className="profile-header">View Photo</p>}
              open={this.state.showImage}
              onCancel={this.imageHandleCancel}
              footer={null}
              width={460}
            >
              <img
                src={this.props?.profileImageUrl}
                style={{ padding: '20px', height: '420px', width: '100%' }}
              />
            </Modal>
            <Modal
              className="logout-modal"
              id="verify-email-modal"
              title={<p className="logout-title">Are you sure you want to change your email ?</p>}
              open={this.state.verifyEmailPopup}
              onCancel={this.verifyEmailPopupHandleCancle}
              style={{ borderRadius: '10px' }}
              footer={
                <div style={{ display: 'flex', justifyContent: 'right', padding: '22px' }}>
                  <Button
                    id="logout-cancel"
                    type="text"
                    style={{
                      color: '#830065',
                      fontSize: '14px',
                      fontWeight: '700',
                      height: '56px',
                      width: '120px',
                    }}
                    onClick={this.verifyEmailPopupHandleCancle}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="logout-success"
                    style={{
                      height: '56px',
                      width: '126px',
                      background: '#830065',
                      borderColor: '#830065',
                      borderRadius: '8px',
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: '700',
                    }}
                    onClick={() => {
                      this.setState({ verifyEmailPopup: false, email: this.state.profileUpdateData.email });
                      this.props?.onUpdateProfile
                        ? this.props?.onUpdateProfile(this.state.profileUpdateData)
                        : this.onUpdateProfile(this.state.profileUpdateData);
                      SuccessMessage('An email has been sent to the new email. Please verify your email.');
                    }}
                  >
                    Yes
                  </Button>
                </div>
              }
            />
          </div>
        );
      }

}

class EditableTagGroup extends React.Component {
    state = {
      tags: this.props.tags ?? [],
      inputVisible: false,
      inputValue: '',
    };
  
    handleClose = removedTag => {
      const tags = this.state.tags.filter(tag => tag !== removedTag);
      this.props.setTags(tags)
      this.setState({ tags });
    };
  
    showInput = () => {
      this.setState({ inputVisible: true }, () => this.input.focus());
    };
  
    handleInputChange = e => {
      this.setState({ inputValue: e.target.value });
    };
  
    handleInputConfirm = () => {
      const { inputValue } = this.state;
      let { tags } = this.state;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      this.props.setTags(tags)
      this.setState({
        tags,
        inputVisible: false,
        inputValue: '',
      });
    };
  
    saveInputRef = input => (this.input = input);
  
    render() {
      const { tags, inputVisible, inputValue } = this.state;
      const tagStyle = { color: "#c180b2", background: "#f6ecf3", border: 'none', borderRadius: "20px", whiteSpace: "unset", padding: "3px 15px", marginBottom: "5px", maxWidth: "100%" }
      return (
        <div className='tag-div'>
          {this.state.tags.map((tag) => {
            const isLongTag = tag.length > 500;
            const tagElem = (
              <Tag key={tag} closable={true} style = {tagStyle} onClose={() => this.handleClose(tag)}>
                {isLongTag ? `${tag.slice(0, 500)}...` : tag}
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {inputVisible && (
            <Input
              ref={this.saveInputRef}
              type="text"
              size="small"
              style={{ width: "100px", height: "28px" }}
              value={inputValue}
              onChange={this.handleInputChange}
              onBlur={this.handleInputConfirm}
              onPressEnter={this.handleInputConfirm}
            />
          )}
          {!inputVisible && (
            <Tag onClick={this.showInput} style={tagStyle}>
              <PlusOutlined style = {{color: "#c180b2"}}/> Add Hobby
            </Tag>
          )}
        </div>
      );
    }
  }
export {ProfileModalContent};
export default NavHeader;
const CustomModal = styled(Modal)(({theme})=>
({
  "& .ant-modal-content":{
    borderRadius:"12px !important",
    overflow:"hidden !important"
  }

})
)
// Customizable Area End