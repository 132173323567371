// Customizable Area Start
import React, { Component } from 'react';
import { Row, Col, Avatar} from 'antd';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import './MyContact.css';
import ContactController from './ContactController.web';
import { noData } from '../../catalogue/src/assets';
class MyContacts extends ContactController {
	constructor(props: any) {
		super(props);
	}

    async componentDidMount(){
        this.getContactList();
    }
   
	render(): React.ReactNode {
		return (
            <PageWrapper title="My Contacts">
               <div className='contact-content'>
                {this.state.contactList.length > 0 ? (
                      <div style={{ width: "100%" }}>
                      <Row gutter={[30, 20]}>
                          {this.state.contactList.map((e: any, index: number) => (
                               <Col xs={24} sm={12} md={12} lg={12} xl={12} key={index}>
                               <div id="up-card" className="contact-card">
                                   <p className="role-type">{e.attributes?.manager_type}</p>
                                   <div className='contact-elements'>
                                   <Avatar
                                        className='image-style'
                                        style={{ cursor: 'pointer', marginRight: '20px' }}  
                                        icon={ <img className='img-card' src='https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/f2db3a81-c413-4f2b-a4ab-117b4fce034d/image.png' />}
                                    />
                                       <div>
                                           <p className="contact-name">{e.attributes?.case_handlers?.full_name}</p>
                                           <p className="contact-email">{e.attributes?.case_handlers?.email}</p>
                                           <p className="contact-phone">{`+${e.attributes?.case_handlers?.full_phone_number.substring(0, 2)} ${e.attributes?.case_handlers?.full_phone_number.substring(2)}`}</p>
                                       </div>
                                   </div>
                               </div>
                           </Col>
                          )

                          )}
                         
                      </Row>
                  </div>
                ) : 
                <div style={{padding: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                    <img src={noData} style={{height: '88px', width: '87px', marginBottom: '10px'}} />
                    <p className='no-data-font'>Results not found</p>
                </div>
                }
                  
                </div>
        </PageWrapper>
		);
	}
}

export default MyContacts;

// Customizable Area End